import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL;

class CameraService {
  // getCameras(site = null){
  //     return axios.get(API_URL + 'camera/list?count=200' + (site === null ? '' : `&site_id=${site}`), {headers: authHeader()});
  // }

  getCameras(site) {
    return axios.get(
      "https://api.varsanpr.com/api/camera?count=200" +
        (site === null ? "" : `&site_id=${site}`),
      { headers: authHeader() }
    );
  }

  getCamera(camera_id) {
    return axios.get(`https://api.varsanpr.com/api/camera/${camera_id}`, {
      headers: authHeader(),
    });
  }

  getModels() {
    return axios.get(`https://api.varsanpr.com/api/camera/models`, {
      headers: authHeader(),
    });
  }

  getMode() {
    return axios.get(`https://api.varsanpr.com/api/camera/modes`, {
      headers: authHeader(),
    });
  }

  //PUT
  updateCamera(camera_id, data) {
    return axios.patch(
      `https://api.varsanpr.com/api/camera/${camera_id}`,
      data,
      { headers: authHeader() }
    );
  }
}

export default new CameraService();
