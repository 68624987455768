<template>
  <div class="row">
    <div v-if="!loading" class="card">
      <div class="card-body">
        <div class="form-group">
          <label for="cameraName" class="form-label"> {{$t('events.filters.camera')}} {{$t('sites.name')}} </label>
          <input class="form-control" type="text" id="cameraName" v-model="camera.name">
        </div>

        <div class="form-group row">
          <label for="inputModel" class="form-label"> {{$t('global.model')}} <span class="text-danger">*</span> </label>
          <div class="col-8">
            <select type="text" class="form-select" id="inputModel" v-model="camera.model">
              <option v-for="model in models" :value="model.slug" :key="model.slug"> {{model.name}} </option>
            </select>
          </div>
          <!-- <div class="col-3">
            <button class="btn btn-primary"> {{$t('global.save')}} </button>
          </div> -->
        </div>

        <div class="form-group row">
          <label for="inputMode" class="form-label"> {{$t('global.mode')}} <span class="text-danger">*</span> </label>
          <div class="col-8">
            <select type="text" class="form-select" id="inputMode" v-model="camera.mode">
              <option v-for="mode in modes" :value="mode.slug" :key="mode.slug"> {{mode.name}} </option>
            </select>
          </div>
          <!-- <div class="col-3">
            <button class="btn btn-primary"> {{$t('global.save')}} </button>
          </div> -->
        </div>

        <div class="row">

          <div class="form-group col-6">
            <label for="inputInstallDate" class="form-label"> {{$t('sites.devices.install_date')}} </label>
            <!-- <timestamp v-if="camera.install_date !== null" :time="camera.intall_date" :format="'dd/MM/yyyy'" class="form-control" id="inputInstallDate" v-model="camera.install_date" :placeholder="camera.install_date"/>
            <input class="form-control" type="date" id="inputInstallDate" v-model="camera.install_date"> -->
            <datepicker format="'dd/MM/yyyy'" placeholder="dd/mm/yyyy" startingview="'day'" v-model="camera.install_date" class="form-control"/>
            <!-- <datepicker :format="'DD/MM/YYYY'" :disabled="camera.retired === 1" input-class="form-control" v-model="camera.last_install" ></datepicker> -->
          </div>

          <div class="form-group col-6">
            <label for="inputMaintenance" class="form-label"> {{$t('sites.devices.last_maintenance')}} </label>
            <input class="form-control" type="date" id="inputMaintenance" v-model="camera.last_maintenance">
          </div>

        </div>

        <div class="row">

          <div class="form-group col-6">
            <label for="inputIP" class="form-label"> IP </label>
            <input v-if="camera.ip !== null" class="form-control" type="text" id="inputIP" v-model="camera.ip">
          </div>

          <div class="form-group col-6">
            <label for="inputMac" class="form-label"> MAC </label>
            <input v-if="camera.mac !== null" class="form-control" type="text" id="inputMac" v-model="camera.mac">
          </div>

        </div>

        <div class="form-group col-6">
          <label for="inputsn" class="form-label"> {{$t('sites.devices.serial')}} </label>
          <input v-if="camera.sn !== null" class="form-control" type="text" id="inputsn" v-model="camera.sn">
        </div>

        <div class="form-group row">
          <label for="inputApi" class="form-label"> API Key </label>
          <div class="col-6">
            <input v-if="camera.api_key !== null" class="form-control" type="password" id="inputApi" v-model="camera.api_key">
          </div>
          <div class="col-3">
            <button class="btn btn-primary" @click="generateKey"> {{$t('global.generate')}} </button>
            <button class="btn btn-secondary m-l-md" @click="copyKey"> {{$t('global.copy')}} </button>
          </div>
        </div>

        <div class="form-group row">
          <label for="inputUsername" class="form-label"> {{$t('login.username')}} </label>
          <div class="col-6">
            <input v-if="camera.username !== null" class="form-control" ref="cameraUsername" type="password" id="inputUsername" v-model="camera.username">
          </div>
          <div class="col-3">
            <button class="btn btn-secondary m-l-md" @click="toggleUsername"> {{$t(showUsername ? 'global.hide' : 'global.show')}} </button>
          </div>
        </div>

        <div class="form-group row">
          <label for="inputpassword" class="form-label"> {{$t('login.password')}} </label>
          <div class="col-6">
            <input v-if="camera.password !== null" class="form-control" ref="cameraPassword" type="password" id="inputpassword" v-model="camera.password">
          </div>
          <div class="col-3">
            <button class="btn btn-secondary m-l-md" @click="togglePassword"> {{$t(showPassword ? 'global.hide' : 'global.show')}} </button>
          </div>
        </div>

        <div v-if="camera.retired !== 1" class="form-group">
          <label for="reverseDirection" class="form-label"> {{$t('sites.devices.reverse_direction')}} ? </label>
          <div class="form-switch">
            <input type="checkbox" class="form-check-input form-control-md" id="reverseDirection" @click="toggleSwitch('flip')" :class="{'on': flip_enabled}">
          </div>
        </div>

        <div v-if="camera.retired !== 1" class="form-group">
          <label for="hideDisplay" class="form-label"> {{$t('sites.devices.hide_display')}} ? </label>
          <div class="form-switch">
            <input type="checkbox" class="form-check-input form-control-md" id="hideDisplay" @click="toggleSwitch('hide')" :class="{'on': hide_enabled}">
          </div>
        </div>

        <div class="flex-center space-between" style="width: 20%; margin: auto;">
          <button class="btn btn-secondary" @click="closeCamera"> {{$t('global.close')}} </button>
          <button class="btn btn-primary" :disabled="!cameraUpdated" @click="saveChanges"> {{$t('global.save_changes')}} </button>
        </div>

      </div>
    </div>
    <!-- <div class="col-12">
      <div class="card widget widget-list">
        <div class="crad-header">
          <h5 class="card-heading"> {{camera?.name}} </h5>
        </div>
        <div class="card-body" style="overflow-x: hidden">
          <form class="row g-3">
            <div class="col-12">
              <label for="inputModel" class="form-label">Model</label>
              <select id="inputModel" class="form-select">
                <option selected>Choose...</option>
                <option v-for="model in models"> {{model.name}} </option>
              </select>
            </div>
            <div class="col-12">
              <button type="submit" class="btn btn-primary">Create New</button>
            </div>
            <div class="col-12">
              <label for="inputMode" class="form-label">Mode</label>
              <select id="inputMode" class="form-select">
                <option selected>Choose...</option>
                <option>...</option>
              </select>
            </div>
            <div class="col-12">
              <button type="submit" class="btn btn-primary">Create New</button>
            </div>
            <div class="col-md-6">
              <label for="inputName" class="form-label">Name</label>
              <input type="text" class="form-control" id="inputName" placeholder="Camera Name">
            </div>
            <div class="col-md-6">
              <label for="inputIp" class="form-label">IP</label>
              <input type="text" class="form-control" id="inputIp" placeholder="IP Address">
            </div>
            <div class="col-12">
              <label for="inputMacAddress" class="form-label">MAC Address</label>
              <input type="text" class="form-control" id="inputMacAddress" placeholder="MAC Address">
            </div>
            <div class="col-12">
              <label for="inputSerialNumber" class="form-label">Serial Number</label>
              <input type="text" class="form-control" id="inputSerialNumber" placeholder="Serial Number">
            </div>
            <div class="col-12">
              <label for="inputApiKey" class="form-label">API Key</label>
              <input type="text" class="form-control" id="inputApiKey" placeholder="API Key">
            </div>
            <div class="col-md-6">
              <label for="inputCameraUsername" class="form-label">Camera Username</label>
              <input type="text" class="form-control" id="inputCameraUsername">
            </div>
            <div class="col-md-6">
              <label for="inputCameraPassword" class="form-label">Camera Password</label>
              <input type="text" class="form-control" id="inputCameraPassword">
            </div>
            <div class="col-12">
              <button type="submit" class="btn btn-primary">Finish and Create</button>
            </div>
          </form>
        </div>
      </div>

    </div> -->
  
  </div>
</template>

<script>
import Timestamp from '../../../../../components/Timestamp.vue';
import CameraService from '../../../../../services/camera.service';
import {_cloneDeep, _omitBy} from 'lodash/cloneDeep';
import {DateTime} from 'luxon';
import Datepicker from 'vue3-datepicker';
import randomBytes from 'randombytes';
// import Datepicker from '../../../../../components/Datepicker.vue';

export default {
  name: 'Camera',
  props: {
    id: Number,  //camera ID
    cameraObj: Object
  },
  components: {Timestamp, Datepicker},
  data() {
    return {
      models: [],
      modes: [],
      loading: true,
      camera: {
        api_key: "",
        flip: false,
        hide: false,
        id: -1,
        install_date: "",
        ip: "",
        last_event: "",
        last_maintenance: "",
        mac: "",
        mode: "",
        model: "",
        mointor: "",
        name: "",
        password: "",
        retired: "",
        retired_by: "",
        retired_on: "",
        site_id: -1,
        sn: "",
        status: "",
        username: ""
      } ,
      originalCamera: {},
      closeCameraDetails: false,
      showUsername: false,
      showPassword: false
    }
  },
  mounted() {
    this.getModels();
    this.getMode();
  },
  methods: {
    getModels() {
      CameraService.getModels()
      .then(response => {
        this.models = response.data.models;
        this.loading = false; 
      })
      .catch(error => {
        this.$error(this.$t('sites.devices.error.models'), error);
      })
    },
    getMode() {
      CameraService.getMode()
      .then(response => {
        this.modes = response.data.modes;
        this.loading = false;
      })
      .catch(error => {
        this.$error(this.$t('sites.devices.error.modes'), error);
      })
    },
    toggleSwitch(value) {
      this.camera[value] = !this.camera[value];
    },
    saveChanges() {
      // this.loading = true;
      let cameraClone = _.cloneDeep(this.camera);
      if(cameraClone.install_date !== null && cameraClone.install_date !== "") {
        cameraClone.install_date = DateTime.fromJSDate(new Date(cameraClone.install_date)).toSeconds();
      }
      if(cameraClone.last_maintenance !== null && cameraClone.last_maintenance !== "") {
        cameraClone.last_maintenance = DateTime.fromJSDate(new Date(cameraClone.last_maintenance)).toSeconds();
      }
      cameraClone = _.omitBy(cameraClone, _.isNil);
      console.log(cameraClone);
      CameraService.updateCamera(cameraClone.id, cameraClone)
      .then(response => {
        this.originalCamera = _.cloneDeep(this.camera);

        console.log(response);
      })
      .catch(error => {
        console.log(error);
      })


    },
    closeCamera() {
      this.closeCameraDetails = true;
      this.$emit('closeCamera', this.closeCameraDetails);
    },
    togglePassword(){
      if(this.$refs.cameraPassword.type === 'password'){
        this.$refs.cameraPassword.type = 'text';
        this.showPassword = true;
      } else {
        this.$refs.cameraPassword.type = 'password';
        this.showPassword = false;
      }
    },
    toggleUsername(){
      if(this.$refs.cameraUsername.type === 'password'){
        this.$refs.cameraUsername.type = 'text';
        this.showUsername = true;
      } else {
        this.$refs.cameraUsername.type = 'password';
        this.showUsername = false;
      }
    },
    copyKey(){
      navigator.permissions.query({name: "clipboard-write"}).then(result => {
        if(result.state == "granted" || result.state == "prompt"){
            navigator.clipboard.writeText(this.camera.api_key);
            this.copied = true;
            this.$success("Copied to clipboard");
            setTimeout(() => {
                this.copied = false;
            }, 2000);
        }
      })
      .catch(error => {
          navigator.clipboard.writeText(this.camera.api_key);
          this.copied = true;
          this.$success("Copied to clipboard");
          setTimeout(() => {
              this.copied = false;
          }, 2000);
      });
    },
    generateKey(){
      this.camera.api_key = randomBytes(32).toString('base64');
    }
  },
  watch: {
    cameraObj: function() {
      this.camera = _.cloneDeep(this.cameraObj);
      this.originalCamera = _.cloneDeep(this.cameraObj);
    }
  },
  computed: {
    flip_enabled: function() {
      return this.camera.flip;
    },
    hide_enabled: function() {
      return this.camera.hide;
    },
    cameraUpdated: function() {
      return !(JSON.stringify(this.camera) === JSON.stringify(this.originalCamera))
    }
  }
}
</script>

<style scoped>
  .form-group {
    margin-bottom: 20px;
  }

  .btn.btn-primary, .btn-secondary {
    padding: 9px 20px;
  }

  .show-btn {
    background-color: rgba(255, 149, 0, .7);
    border: 2px solid transparent;
    color: white;
    padding: 9px 20px;
  }

  .show-btn:hover {
    border-color: #FF9500;
  }
</style>